import React from "react";

function MapaCADathon() {
  return (
    <iframe
      width="100%" // You can adjust this width
      height="100vh"
      style={{
        height: "100vh",
      }}
      src="https://embed.figma.com/proto/VoONNHHC3XUvt3Ys6maiQG/MAPA?page-id=152%3A189&node-id=152-373&node-type=canvas&viewport=269%2C202%2C0.06&scaling=contain&content-scaling=fixed&embed-host=share"
      allowFullScreen
    ></iframe>
  );
}

export default MapaCADathon;
