import React, { useEffect, useRef, useState } from "react";
import Fire from "../utils/Fire";
import "../Assets/scss/voluntari.scss";
import Footer from "../Components/Footer";
import axios from "axios";
import { toast } from "react-toastify";

const AXIOS = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_API,
//   baseURL: "http://localhost:3001/",

  headers: {
    "Content-Type": "application/json",
  },
  withCredentials: true,
});

function Cazari() {
  const [nr, setNr] = useState("");
  const [voluntar, setVoluntar] = useState({});
  const search = async () => {
    await AXIOS.get(`/getBobociByCNP/${nr}`).then((res) => {
      console.log(res);
      if (res.data.code == 200) {
        setVoluntar(res.data.data);
      } else {
        toast(res.data.message);
      }
      setNr("");
      executeScroll();
    });
  };

  const myRef = useRef(null);
  const executeScroll = () => {
    myRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const check = async (event) => {
    if (event.key === "Enter") {
      await search();
    }
  };
  return (
    <>
      <section className="search">
        <img
          src={require("../Assets/images/vale.svg").default}
          className="vale"
          alt=""
        />
        <div className="find">
          <h1>
            Caută-ți CNP-ul pentru a-ți afla specializarea și domeniul tău
          </h1>
          <div className="row">
            <input
              autoFocus={true}
              type="text"
              onKeyDown={check}
              value={nr}
              onChange={(e) => setNr(e.target.value.toUpperCase())}
              placeholder="CNP"
            />
            <div className="button" onClick={search}>
              <span>Caută</span>
            </div>
          </div>
        </div>

        <br />
        <br />
        <div className="rezumat" ref={myRef}>
          {Object.keys(voluntar).length != 0 && (
            <>
              <ul>
                <li>
                  Nume: <b> {voluntar.nume}</b>
                </li>
                <li>
                  Email: <b> {voluntar.email}</b>
                </li>
                <li>
                  {" "}
                  Numar de telefon: <b> {voluntar.telefon}</b>
                </li>
                <li>
                  {" "}
                  Domeniu: <b> {voluntar.domeniu}</b>
                </li>
                <li>
                  {" "}
                  Specializare: <b> {voluntar.specializare}</b>
                </li>
                <li>
                  {" "}
                  Ora la care esti programat: <b> {voluntar.ora.split(" - ")[1]}</b>
                </li>
              </ul>
            </>
          )}
        </div>

        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
      </section>
      <Footer />
    </>
  );
}

export default Cazari;
